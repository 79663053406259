import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import styled from 'styled-components';
import Hero from './components/hero/Hero';
import ProductDescription from './components/productDescription/ProductDescription';
import Contact from './components/contact/Contact';

function App() {
  return (
    <AppSC>
      <Header />
      <Hero />
      <ProductDescription />
      <Contact />
    </AppSC>
  );
}

const AppSC = styled.div`
  display : flex; 
  flex-direction : column;
  min-width : 100vw;
  max-width : 100vw;
`

export default App;
