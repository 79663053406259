import React from 'react';
import styled from 'styled-components';
import Header from '../header/Header';

const Hero = () => {

    const rendering = () => {
        return (

            <HeroSC>
                <div className='hero-text-container'>
                    <h1 className='hero-text-title'>
                        We help organizations that sell trainings
                    </h1>
                    <p className='hero-text-description'>
                        Holli provides one platform to manage all your trainings and automate your operations
                    </p>
                    <button>
                        Request a demo
                    </button>
                </div>
                <div className='hero-image-container'>
                    <img src='/holli-ventje.png' alt='hero' />
                </div>
                
            </HeroSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeroSC = styled.div`
    min-width : 100vw;  
    max-width : 100vw;
    min-height : 440px;   
    max-height : 440px;
    height : 440px;
    background: linear-gradient(to top right, #3593D1 30%, #35B0E5 56%);
    display : flex; 
    align-items : center;
    box-sizing : border-box;

    .hero-text-container {
        max-width : 600px;
        box-sizing : border-box;
        margin-left : 100px;
    }

    .hero-text-title {
        font-size : 3.5rem;
        color : white;
        font-family : 'Outfit', sans-serif;
        line-height : 0.8;
    }

    .hero-text-description {
        max-width : 550px;
        margin-top : 10px;
        font-size : 1.5rem;
        color : white;
        font-family : 'Inter', sans-serif;
        font-weight : 200;
        letter-spacing: -1px;
    }
    
    button {
        border-radius : 5px;
        padding : 10px 45px;
        margin-top : 20px;
        background-color : rgba(255, 255, 255, 0.4);
        color : white;
        outline : none;
        border : none;
        cursor : pointer;
        font-size : 1.2rem;
        font-family : 'Outfit' , sans-serif;

        &:hover {
            background-color : rgba(255, 255, 255, 0.6)
        }
    }

    .hero-image-container {
        flex-grow : 1;
        justify-content : flex-end;
        display : flex;
        padding-right : 50px;



    }

    @media (max-width: 768px) {
        min-height : 320px;   
        max-height : 320px;
        height : 320px;
        position : relative;

        .hero-text-container {
            margin-left : 20px;
            max-width : 200px;
        }

        .hero-text-title {
            font-size : 2rem;
        }

        .hero-text-description {
            font-size : 1rem;
        }

        button {
            padding : 10px 25px;
            font-size : 1rem;
        }

        .hero-image-container {
            flex-grow : 1;
            justify-content : flex-end;
            display : flex;
            padding-right : 50px;

            img {
                min-width : 45%;
                max-width : 45%;    
                position : absolute; 
                bottom : 0; 
                right : 0;
            }
        }
    }

`

export default Hero