import React from 'react';
import styled from 'styled-components';
import RequestDemoForm from './RequestDemoForm';

const ProductDescription = () => {

    const rendering = () => {
        return (
            <ProductDescriptionSC>
                <div className='features-description'>
                    <p className='features-header'>
                        Focus on your content. <br />Let Holli take care of     
                    </p>
                    <ul>
                        <li>Registration</li>
                        <li>Payments</li>
                        <li>Certification</li>
                        <li>Scheduled e-mail</li>
                        <li>Feedback surveys</li>
                        <li>Follow-up</li>
                        <li>Reporting</li>
                    </ul>
                    <img src='/stoel-man.png' alt='dude' />

                </div>
                <RequestDemoForm />
                
            </ProductDescriptionSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ProductDescriptionSC = styled.div`
    min-width : 100vw;
    max-width : 100vw;
    overflow : hidden;
    display : flex;
    box-sizing : border-box;
    padding : 25px;
    min-height : 400px;

    .features-description {
        max-width : 50%;
        min-width : 50%;
        box-sizing : border-box;
        padding : 25px;
        padding-left : 75px;
        position : relative;

        .features-header {
            font-size : 2.3rem;
            font-family : 'Outfit', sans-serif;
            line-height : 0.85;
            letter-spacing : -0.3px;
        }

        ul {
            list-style-type : none;
            padding : 0;
            margin : 0;
            margin-top : 15px;
            li {
                font-size : 1.5rem;
                font-family : 'Outfit', sans-serif;
                color : #1f1f1f;
                line-height : 0.98;
                cursor : pointer;
                &:hover {
                }
            }
        }

        &>img {
            min-height : 300px;
            max-height : 300px;
            object-fit : cover;
            position : absolute; 
            right : 80px;
            bottom : 25px;
        }
    
    }

    @media (max-width: 768px) {
        flex-direction : column;
        padding : 25px;

        .features-description {
            max-width : 100%;
            min-width : 100%;
            padding : 0;
            padding-left : 0;
            position : relative;

            .features-header {
                font-size : 1.7rem;
            }

            ul {
                margin-top : 15px;
                li {
                    font-size : 1rem;
                }
            }

            &>img {
                min-height : 50%;
                max-height : 50%;
                object-fit : cover;
                position : absolute; 
                right : 0;
                bottom : 0;
            }
        
        }
        
    
    }

`

export default ProductDescription