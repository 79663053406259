import React from 'react';
import styled from 'styled-components';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const Contact = () => {

    const rendering = () => {
        return (
            <ContactSC>
                <p className='contact-title'>contact</p>
                <div className='contact-row'>
                    <EmailIcon />
                    <p>info@holli-training.com</p>
                </div>
                <div className='contact-row'>
                    <PhoneIcon />
                    <p>(+32) 4 71 10 97 23</p>
                </div>
            </ContactSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ContactSC = styled.div`
    border-top : 1px solid lightgrey;
    padding : 25px;
    background-color : #f9f9f9;
    font-family : 'Inter', sans-serif;
    display : flex;
    flex-direction : column;

    .contact-title {
        margin-bottom : 10px;
    }

    .contact-row {  
        display : flex;
        align-items : center;

        &>p {
            margin-left:  10px;
        }

        &>svg {
            color : #a3a3a3;
            border-right : 1px solid lightgrey;
            padding-right : 5px;
        }

    }
`

export default Contact