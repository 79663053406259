import React from 'react';
import styled from 'styled-components';

const Header = () => {

    const rendering = () => {
        return (
            <HeaderSC>
                <div className='logo-container'>
                    <img src='/holli-training-logo.png' alt='logo' />
                </div>
                <div className='menu-container'>
                    <ul>
                        <li>Home</li>
                        <li>About</li>
                        <li>Contact</li>
                    </ul>
                </div>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}


const HeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    min-height : 70px;
    background-color : white;
    font-family : 'Outfit', sans-serif;

    .logo-container {
        display : flex;
        align-items : center;

        img {
            max-height : 40px;
            margin : 0 10px;
        }
    }

    .menu-container {
        display : flex;
        justify-content : space-between;
        align-items : center;
        margin-right : 10px;

        ul {
            display : flex;
            list-style-type : none;
            li {
                margin : 0 10px;
                cursor : pointer;
                &:hover {
                    color : #f0f0f0;
                }
            }
        }
    }

    @media (max-width: 768px) {

        min-height : 50px;

        .logo-container {
            img {
                max-height : 30px;
            }
        }

        .menu-container {
            display : none;
        }
    }


`

export default Header