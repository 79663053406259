import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { TextField , Button, Stack } from '@mui/material';

const RequestDemoForm = () => {

    const { register, handleSubmit, formState : errors } = useForm();


    const onSubmit = (data) => {
        console.log(data);
    }

    const rendering = () => {
        return (
            <RequestDemoFormSC>
                <h1 className='request-a-demo-title'>Request a demo</h1>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            required
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            {...register('firstName',
                                { required : 'first name is required' }
                            )}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                        />
                        <TextField
                            required
                            id="lastName"
                            label="Last Name"
                            variant="outlined"
                            {...register('lastName',
                                { 
                                    required : 'Last name is required'
                                }
                            )}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                        />
                        <TextField
                            required
                            id="email"
                            label="Email"
                            variant="outlined"
                            {...register('email', 
                                { 
                                    required : 'Email is required', 
                                    pattern : {
                                        value : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message : 'Not a valid email'
                                    }
                                }
                            )}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                        <TextField
                            required
                            id="company"
                            label="Company"
                            variant="outlined"
                            {...register('company',
                                { required : 'Company is required' }
                            )}
                            error={!!errors.company}
                            helperText={errors.company?.message}
                        />
                        <TextField
                            required
                            id="role"
                            label="Role"
                            variant="outlined"
                            {...register('role',
                                { required : 'Role is required' }
                            )}
                            error={!!errors.role}
                            helperText={errors.role?.message}

                        />
                        <Button variant="contained" color="primary" type="submit">Submit</Button>
                </form>
            </RequestDemoFormSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RequestDemoFormSC = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    flex-grow : 1;
    padding : 25px;
    max-width : 600px;

    & form {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
        gap : 15px;
    }

    .request-a-demo-title {
        font-size : 2.3rem;
        font-family : 'Outfit', sans-serif;
        line-height : 0.85;
        letter-spacing : -0.3px;
        font-weight : 400;
        margin-bottom : 25px;
    }

    @media (max-width: 768px) {
        padding : 0;
        padding-top : 25px;

        .request-a-demo-title {
            font-size : 1.7rem;
        }
    }
`

export default RequestDemoForm